import { STERILIZATION_SPECIFICATIONS_CONTAINERSIZES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "custom-list-url": _vm.containerSizesCustomUrl, "title": "Edit Container Size" } }, [_c("select-input", { key: "facility", attrs: { "data-source": _vm.facilities, "label": "Facility", "on-select-change": _vm.onFacilitySelect, "parse-value": _vm.parseFacility, "rules": "required", "source": "code", "span": 24 } }), _c("select-input", { key: "type", attrs: { "reference": "sterilization.common.container-types", "source": "name", "label": "Container Type", "span": 12, "rules": "required" } }), _c("text-input", { key: "name", attrs: { "label": "Container Size", "placeholder": "Type Container Size", "max-length": 20, "form-item": "", "rules": "required", "span": 12 } }), _c("select-input", { key: "fillingLines", attrs: { "delete-filter": "IsInactive", "label": "Line", "lazy": "", "mode": "multiple", "parse-value": _vm.getLineIds, "queries": _vm.fillingLinesQueries, "reference": "sterilization.common.filling-lines", "rules": "required", "search-by": "Description", "source": "id", "source-label": "description", "span": 24 } }), _c("text-input", { key: "description", attrs: { "label": "Nominal Description", "placeholder": "Nominal Description", "max-length": 50, "form-item": "", "rules": "required", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditContainerSize",
  inject: ["apiUrl", "crud"],
  data() {
    return {
      selectedFacility: "PK",
      facilities: [{ code: "PK" }, { code: "PP" }, { code: "PT" }]
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity() || {};
    },
    fillingLinesQueries() {
      return [["facility", this.selectedFacility]];
    }
  },
  created() {
    this.selectedFacility = this.entity.facility;
  },
  methods: {
    getLineIds(val) {
      if (val && val.length && typeof val[0] == "object") {
        const ids = [...val].map((item) => item.id);
        return ids;
      }
      return val;
    },
    parseFacility(value) {
      return value.toUpperCase();
    },
    onFacilitySelect(value) {
      this.selectedFacility = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditContainerSizes = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-container-sizes" }, [_c("resource", { attrs: { "name": "sterilization.common.filling-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.can-sizes", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/container-sizes" } }, [_c("edit-container-sizes")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditContainerSizes
  },
  data() {
    return {
      page: STERILIZATION_SPECIFICATIONS_CONTAINERSIZES,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
